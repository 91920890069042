export const WORDS = [
  'compl',
 'enums',
 'float',
 'using',
 'union',
'abort',
'addin',
'addon',
'algol',
'apple',
'arial',
'ascii',
'await',
'basic',
'block',
'break',
'board',
'cache',
'catch',
'cdrom',
'class',
'click',
'close',
'cobol',
'coral',
'const',
'crash',
'debug',
'email',
'eprom',
'erase',
'excel',
'ezine',
'field',
'false',
'final',
'flash',
'forth',
'forum',
'frame',
'gates',
'image',
'input',
'intel',
'laser',
'layer',
'logic',
'login',
'logon',
'macro',
'media',
'micro',
'modem',
'morph',
'mouse',
'msdos',
'octal',
'paint',
'panel',
'patch',
'pixel',
'popup',
'proxy',
'purge',
'query',
'queue',
'reset',
'robot',
'spool',
'short',
'super',
'stack',
'stats',
'struct',
'suite',
'table',
'throw',
'track',
'tweet',
'virus',
'while',
'write',
'yield',
]
